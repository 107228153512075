@font-face {
	font-family: 'Sparkasse-Regular';
	src: url(./fonts/Sparkasse-Webfonts/WOFF/Sparkasse_web_Rg.woff) format('woff');
	font-weight: normal;
}

@font-face {
	font-family: 'Sparkasse-Bold';
	src: url(./fonts/Sparkasse-Webfonts/WOFF/Sparkasse_web_Bd.woff) format('woff');
	font-weight: bold;
}

@font-face {
	font-family: 'Sparkasse-Bold-Italic';
	src: url(./fonts/Sparkasse-Webfonts/WOFF/Sparkasse_web_BdIt.woff) format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Sparkasse-Italic';
	src: url(./fonts/Sparkasse-Webfonts/WOFF/Sparkasse_web_It.woff) format('woff');
	font-style: italic;
}

@font-face {
	font-family: 'Sparkasse-Ligher';
	src: url(./fonts/Sparkasse-Webfonts/WOFF/Sparkasse_web_Lt.woff) format('woff');
	font-weight: lighter;
}

@font-face {
	font-family: 'Sparkasse-Bolder';
	src: url(./fonts/Sparkasse-Webfonts/WOFF/Sparkasse_web_Md.woff) format('woff');
	font-weight: bolder;
}

@font-face {
	font-family: 'Sparkasse-Head';
	src: url(./fonts/Sparkasse-Webfonts/WOFF/SparkasseHead_web_Rg.woff) format('woff');
}

@font-face {
	font-family: 'Sparkasse-Serif';
	src: url(./fonts/Sparkasse-Webfonts/WOFF/SparkasseSerif_web_Rg.woff) format('woff');
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: Sparkasse-Regular, -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a:hover svg g circle {
	fill: #777;
}

a:hover svg g path {
	fill: #fff;
}

#root {
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media screen and (max-width: 768px) {
	body #tc-privacy-wrapper,
	body #header_tc_privacy {
		height: auto !important;
	}
}

.online-application-tooltip > .tooltip-inner {
	max-width: 100%;
}

.tooltip-inner {
	font-size: 13px;
}
